<template>
  <CModal id="CreateLogisticsTracking" class="ActionModel" :show.sync="Toggle" :centered="true" size="lg" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template v-if="!Loading">
      <CAlert color="info">
        <CIcon name="cil-bell" class="mr-2" />{{ $t('Order.LogisticsTracking.Info') }}
      </CAlert>
      <CAlert v-if="$parent.Data.Logistics.System !== 'CUSTOM'" color="warning">
        <i class="fas fa-info-circle mr-2" />{{ $t('Order.LogisticsTracking.Warning') }}
      </CAlert>
      <CRow form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsTracking.CommonProvider') }}
        </CCol>
        <CCol sm="9">
          <CCard>
            <CCardBody class="py-2 px-3">
              <CInputRadioGroup id="CommonProvider" v-model="LogisticsProvider" size="sm" :options="LogisticsProviders" :checked.sync="LogisticsProvider" :custom="true" inline />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CInput :label="$t('Order.LogisticsTracking.ProviderName')" v-model="Data.Name" horizontal/>
      <CInput :label="$t('Order.LogisticsTracking.TrackUrl')" v-model="Data.Link" horizontal/>
      <CInput :label="$t('Order.LogisticsTracking.TrackingNum')" v-model="Data.BookingNo" horizontal/>
      <CRow form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Logistics.ExpectedShippingDate') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="Data.ShippingDate" mode="date" :min-date="new Date()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Logistics.AssignDate') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="Data.AssignDate" mode="date" :min-date="new Date()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CSelect :label="$t('Order.LogisticsTracking.ChangeStatus')" :options="ChangeStatusOption" v-model="Data.Status" :value.sync="Data.Status" :placeholder="$t('Global.PleaseSelect')" horizontal/>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.LogisticsTracking.Title')}}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="UpdateData()" color="success" :disabled="$parent.Data.Logistics.System !== 'CUSTOM'">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "CreateLogisticsTracking",
  props: {
    Toggle: {
      type: Boolean,
      default: false
    },
    OrderNum: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      Submit: false,
      Loading: false,
      Data: {
        Name: '',
        Link: '',
        BookingNo: '',
        Status: 'P',
        TradeNo: '',
        ShippingDate: new Date(),
        AssignDate: ''
      },
      LogisticsProvidersURL: {
        'UNIMART': 'https://eservice.7-11.com.tw/E-Tracking/search.aspx',
        'FAMI': 'https://www.famiport.com.tw/Web_Famiport/page/process.aspx',
        'HILIFE': 'https://www.hilife.com.tw/serviceInfo_search.aspx',
        'OKMART': 'https://ecservice.okmart.com.tw/Tracking/Search',
        'TCAT': 'http://www.t-cat.com.tw/Inquire/Trace.aspx',
        'ECAN': 'http://query2.e-can.com.tw/%E5%A4%9A%E7%AD%86%E6%9F%A5%E4%BB%B6A.htm',
        'HCT': 'https://www.hct.com.tw/Search/SearchGoods_n.aspx',
        'KERRYTJ': 'https://www.kerrytj.com/ZH/search/search_track.aspx',
        'KERRYEXPRESS': 'https://www.kerrytj.com/ZH/search/search_track.aspx',
        'SF': 'https://htm.sf-express.com/tw/tc/dynamic_function/waybill',
        'FEDEX': 'https://www.fedex.com/zh-tw/tracking.html',
        'DHL': 'https://www.dhl.com/tw-en/home/tracking.html',
        'OCS': 'https://webcsw.ocs.co.jp/csw/ECSWG0201R00000P.do',
        'UCF': 'https://www.ucf.com.tw/cargo_tracking',
        'TP': 'http://postserv.post.gov.tw/pstmail/main_mail.html',
        "DPEX": "https://dpex.com/track-and-trace",
        "SKYNET": "https://www.skynetworldwide.com/tracking.html"
      },
      LogisticsProvider: ''
    }
  },
  computed: {
    LogisticsProviders () {
      return Object.keys(this.LogisticsProvidersURL).map(code => {
        return {
          value: code,
          label: this.$t('Logistics.LogisticsProvider.' + code),
          Url: this.LogisticsProvidersURL[code]
        }
      })
    },
    ChangeStatusOption () {
      return Object.keys(this.$t('Order.LogisticsStatusList')).filter(status => ['P', 'H', 'U', 'O', 'S', 'Y'].includes(status)).map(status => {
        return {
          value: status,
          label: status === 'P' ? this.$t('Order.LogisticsTracking.NoChange') : this.$t('Order.LogisticsStatusList.' + status),
        }
      })
    }
  },
  watch: {
    Toggle (value) {
      if(value) this.LoadData()
    },
    LogisticsProvider() {
      const LogisticsProviderData = this.LogisticsProviders.filter((item) => item.value === this.LogisticsProvider)
      this.Data.Name = LogisticsProviderData[0].label || ''
      this.Data.Link = LogisticsProviderData[0].Url || ''
      if (this.LogisticsProvider === '') this.Data.Name = ''
    }
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:OrderNum', '')
      this.Data = {
        Name: '',
        Link: '',
        BookingNo: '',
        Status: 'P',
        TradeNo: '',
        ShippingDate: new Date(),
        AssignDate: ''
      }
    },
    LoadData() {
      if ('LogisticsData' in this.$parent.Data && this.$parent.Data.LogisticsData.System === 'CUSTOM') {
        this.Data.Name = this.$parent.Data.LogisticsData.Method
        this.Data.Link = this.$parent.Data.LogisticsData.TrackURL
        this.Data.BookingNo = this.$parent.Data.LogisticsData.BookingNo
        this.Data.TradeNo = this.$parent.Data.LogisticsData.TradeNo
        this.Data.ShippingDate = this.$parent.Data.LogisticsData.ShippingDate
        this.Data.AssignDate = this.$parent.Data.LogisticsData.AssignDate
      }
    },
    UpdateData() {
      const LogisticsInfo = {
        BookingNo: this.Data.BookingNo,
        IsPayment: this.$parent.Data.Status.Payment === 'Y',
        LabelImage: '',
        MerchantOrderNo: this.OrderNum,
        Method: this.Data.Name, //配送方式
        OrderNo: this.OrderNum,
        Quantity: '',
        System: 'CUSTOM',
        TradeNo: this.Data.BookingNo, //物流單號
        Type: '',
        TrackURL: this.Data.Link, //物流公司的追蹤貨態網址
        ShippingDate: this.$dayjs(this.Data.ShippingDate).format('YYYY-MM-DD'),
        AssignDate: this.$dayjs(this.Data.AssignDate).format('YYYY-MM-DD'),
        Status: this.Data.Status
      }
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/order/addLogisticsTracking',
        method: 'post',
        data: {
          LogisticsInfo
        }
      }).then(() => {
        this.Submit = false
        this.$Progress.finish()
        this.$emit('Success')
        this.HandleHideModal()
        this.$parent.GerOrder()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/OrderTrackingSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.Submit = false
        this.$Progress.fail()
        this.$emit('Error', error)
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/OrderChangeFail') + error.Message,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
#CommonProvider {
  .custom-control {
    padding-left: 0;
    margin-right: 0;
    &::after {
      content: '/';
      display: inline-block;
      margin: 0 5px;
      color: #aaa;
    }
  }
  .custom-control:last-child {
    &::after {
      display: none;
    }
  }
  .custom-control-input, .custom-radio .custom-control-label::before, .custom-radio .custom-control-label::after {
    display: none;
  }
  label {
    font-weight: normal;
    cursor: pointer;
    color: var(--info)
  }
}
</style>
